import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, User, UserCredential, signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile, signOut } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { auth, db } from './firebaseConfig';

interface AuthContextProps {
  currentUser: User | null;
  login: (email: string, password: string) => Promise<UserCredential>;
  signup: (email: string, password: string, firstName: string) => Promise<void>;
  logout: () => Promise<void>;
  userEvents: string[];
  addEventToUser: (eventId: string) => Promise<void>; 
}

const AuthContext = createContext<AuthContextProps>({
  currentUser: null,
  login: async () => Promise.resolve({} as UserCredential),
  signup: async () => {}, 
  logout: async () => {},
  userEvents: [],
  addEventToUser: async () => {}, 
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userEvents, setUserEvents] = useState<string[]>([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserEvents(docSnap.data().events || []);
        }
      }
    });
    return unsubscribe;
  }, []);

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signup = async (email: string, password: string, firstName: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (auth.currentUser) {
        await updateProfile(auth.currentUser, { displayName: firstName });
      }

      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        firstName: firstName,
        events: []
      });

    } catch (error: any) {
      console.error("Error during sign up:", error.message);
      console.error("Error details:", error);
      throw error;
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  const addEventToUser = async (eventId: string) => {
    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        events: arrayUnion(eventId)
      });
      setUserEvents(prevEvents => [...prevEvents, eventId]);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, signup, logout, userEvents, addEventToUser }}>
      {children}
    </AuthContext.Provider>
  );
};
