import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import '../styles/dashboard.css';

interface EventDetails {
  name: string;
  ownerName: string;
  members: string[];
}

const Dashboard: React.FC = () => {
  const { currentUser, userEvents } = useAuth();
  const [firstName, setFirstName] = useState<string>('');
  const [eventsDetails, setEventsDetails] = useState<(EventDetails & { id: string })[]>([]);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  useEffect(() => {
    const fetchFirstName = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setFirstName(docSnap.data().firstName);
        } else {
          console.log('No such document!');
        }
      }
    };

    fetchFirstName();
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      const eventsPromises = userEvents.map(async (eventId) => {
        const docRef = doc(db, 'events', eventId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data() as EventDetails;
          return { ...data, id: eventId };
        } else {
          console.log(`No such event with ID: ${eventId}`);
          return null;
        }
      });

      const eventsData = await Promise.all(eventsPromises);
      setEventsDetails(eventsData.filter(event => event !== null) as (EventDetails & { id: string })[]);
    };

    fetchEventDetails();
  }, [userEvents]);

  return (
    <div>
      <div className="limiter">
        <div className="container">
          <div className="wrap-dashboard">
            {currentUser ? <h1>Hey, {firstName}!</h1> : null}
            <h2 className='sub_title'>Vos événements</h2>
            <div className="events_container">
              {eventsDetails.map((event, index) => (
                <div className='cards-events-list' key={index}>
                  <a
                    className='card-event'
                    href="#"
                    onClick={() => navigate(`/event/${event.id}`)}
                  >
                    <div className="card-event-item_bg"></div>
                    <div className="card-event-item_title">
                      {event.name}
                    </div>
                    <div className="card-event-item_ownerName">
                      (Organisé par : {event.ownerName} )<br></br>
                      <span className="card-event-item_members">
                        Membres : {event.members.join(', ')}
                      </span>
                    </div>
                  </a>
                </div>
              ))}
            </div>

            <div className='dashboard_action_container'>
              <button onClick={() => navigate('/create-event')}>Créer un événement</button>
              <button onClick={() => navigate('/join-event')}>Rejoindre un événement</button>
              <button onClick={handleLogout}>Déconnexion</button>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Dashboard;
