// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import SignUp from './components/SignUp';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import Dashboard from './pages/Dashboard';
import CreateEvent from './components/events/CreateEvent';
import JoinEvent from './components/events/JoinEvent';
import EventPage from './components/events/EventPage';
import EventDetails from './components/events/EventDetails';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/create-event" element={<CreateEvent />} />
          <Route path="/join-event" element={<JoinEvent />} />
          <Route path="/event/:eventId" element={<EventDetails />} />
          <Route path="/event/:eventId" element={<EventPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
