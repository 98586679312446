import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import '../../styles/event_detail.scss';
import GridEvent from '../utils/GridEvent';

interface EventDetailsProps {
  name: string;
  ownerName: string;
  members: string[];
  eventCode: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  location?: string;
  budget?: number;
  photos?: string[];
}

const EventDetails: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { currentUser } = useAuth();
  const [event, setEvent] = useState<EventDetailsProps | null>(null);
  const [newEventName, setNewEventName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [budget, setBudget] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventDetails = async () => {
      if (eventId) {
        const docRef = doc(db, 'events', eventId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data() as EventDetailsProps;
          setEvent(data);
          setNewEventName(data.name);
          setDescription(data.description || '');
          setStartDate(data.startDate || '');
          setEndDate(data.endDate || '');
          setLocation(data.location || '');
          setBudget(data.budget || 0);
        } else {
          console.log(`No such event with ID: ${eventId}`);
        }
      } else {
        console.log('No event ID provided');
      }
    };

    fetchEventDetails();
  }, [eventId]);

  const handleUpdateEvent = async () => {
    if (event && currentUser && event.ownerName === currentUser.displayName) {
      if (eventId) {
        const docRef = doc(db, 'events', eventId);
        await updateDoc(docRef, {
          name: newEventName,
          description,
          startDate,
          endDate,
          location,
          budget,
        });
        setEvent({
          ...event,
          name: newEventName,
          description,
          startDate,
          endDate,
          location,
          budget,
        });
      } else {
        console.log('No event ID provided');
      }
    } else {
      alert('You do not have permission to edit this event');
    }
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  if (!event) {
    return <div>Chargement...</div>;
  }


  return (
    <div>
      <div className="limiter">
        <div className="container">
          <div className="wrap_event_detail">
            <div className="event-details">
              <h1 className='event_title'>{event.name}</h1>
              <h3 className='event_sub_title'>Evénement organisé par : {event.ownerName}</h3>


              <div className="projects-section-line">
                <div className="projects-status">
                  <div className="item-status">
                    <span className="status-number">{event.location ? event.location : "???"}</span>
                    <span className="status-type">Localisation</span>
                  </div>
                  <div className="item-status">
                    <span className="status-number">{event.budget ? event.budget : "???"} €</span>
                    <span className="status-type">Budget par personne</span>
                  </div>
                  <div className="item-status">
                    <span className="status-number">{event.eventCode}</span>
                    <span className="status-type">Code de l'événement</span>
                  </div>
                </div>
                <div className="projects-status">
                <div className="item-status">
                    <span className="status-number">{event.startDate ? formatDate(event.startDate) : "???"}</span>
                    <span className="status-type">Date de départ</span>
                  </div>
                  <div className="item-status">
                    <span className="status-number">{event.endDate ? formatDate(event.endDate) : "???"}</span>
                    <span className="status-type">Date de fin</span>
                  </div>
                  </div>

              </div>

              <div className="grid_container">
                <GridEvent
                  title="Gestion financière"
                  subtitle="Budget total"
                  progress={30}
                  participants={[
                    "https://images.unsplash.com/photo-1448932223592-d1fc686e76ea",
                  ]}
                  daysLeft={10}
                  color="#FEE4CB"
                />
                <GridEvent
                  title="Sondage"
                  subtitle="Sondage en cours"
                  participants={[
                    "https://images.unsplash.com/photo-1534151058711-38dca5d0a1d5",
                  ]}
                  color="#E9E7FD"
                />
                <GridEvent
                  title="Liste de courses"
                  subtitle="Articles restants"
                  progress={50}
                  participants={[
                    "https://images.unsplash.com/photo-1517423440428-a5a00ad493e8",
                  ]}
                  color="#DBF6FD"
                />
              </div>
              <p>Membres : {event.members.join(', ')}</p>
              <p>Description: {event.description}</p>

              {currentUser && event.ownerName === currentUser.displayName && (
                <div>
                  <h2>Edition de l'événement</h2>
                  <input
                    className='event_input'
                    type="text"
                    value={newEventName}
                    onChange={(e) => setNewEventName(e.target.value)}
                    placeholder="Nom de l'événement"
                  />
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                  />
                  <input
                    className='event_input'
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <input
                    className='event_input'
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  <input
                    className='event_input'
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="Localisation"
                  />
                  <p>Budget de l'événement</p>
                  <input
                    className='event_input'
                    type="number"
                    value={budget}
                    onChange={(e) => setBudget(Number(e.target.value))}
                    placeholder="Budget"
                  />
                  <button onClick={handleUpdateEvent}>Mettre a jour l'événement</button>
                </div>
              )}

              <button onClick={() => navigate('/dashboard')}>Retour au Dashboard</button>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default EventDetails;
