import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const EventPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<any>(null);

  useEffect(() => {
    const fetchEvent = async () => {
      if (eventId) {
        const docRef = doc(db, 'events', eventId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setEvent(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchEvent();
  }, [eventId]);

  const handleCopy = () => {
    navigator.clipboard.writeText(event.eventCode).then(() => {
      alert('Texte copié dans le presse-papiers!');
    }).catch((err) => {
      console.error('Échec de la copie dans le presse-papiers', err);
    });
  };

  
  return (
    <div>
      <h1>Page de l'événement</h1>
      {event && (
        <>
          <h2>{event.name}</h2>
          <p>Propriétaire: {event.ownerName}</p> 
          <p>Membres: {event.members.join(', ')}</p>
          <p>Partagez ce code pour rejoindre l'événement : {event.eventCode}</p> 
          <button onClick={handleCopy}>Copier dans le presse-papiers</button><br></br>
          <Link to="/dashboard">Retour au dashboard</Link>
        </>
      )}
    </div>
  );
};

export default EventPage;
