import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDAx0-QsOP0drwUnOyYkAzSd014EigqZ64",
    authDomain: "vacancy-app-1fb9e.firebaseapp.com",
    projectId: "vacancy-app-1fb9e",
    storageBucket: "vacancy-app-1fb9e.appspot.com",
    messagingSenderId: "138116554819",
    appId: "1:138116554819:web:915dab572dd780c4fe34f6",
    measurementId: "G-3EWMR90F68"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
