import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { getAuth, fetchSignInMethodsForEmail } from 'firebase/auth';
import { ReactComponent as VacancyIcon } from '../vendor/svg-icons/indicator-svgrepo-com.svg';
import { ReactComponent as PasswordEyeIcon } from '../vendor/svg-icons/eye-password.svg';
import { ReactComponent as ClosePasswordEyeIcon } from '../vendor/svg-icons/close-eye-password.svg';
import '../styles/signup.scss';

const SignUp: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const { signup } = useAuth();
  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let valid = true;

    // Vérifier le format de l'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('⚠️ Le format de l\'email est invalide !');
      valid = false;
    } else {
      setEmailError('');
    }

    // Vérifier si le mot de passe contient au moins 6 caractères
    if (password.length < 6) {
      setPasswordError('⚠️ Le mot de passe doit contenir au moins 6 caractères !');
      valid = false;
    } else {
      setPasswordError('');
    }

    if (!valid) return;

    try {
      setLoading(true);

      // Vérifier si l'email existe déjà
      const emailExists = await checkIfEmailExists(email);

      if (emailExists) {
        setEmailError('⚠️ Cet email est déjà utilisé !');
        setLoading(false);
        return;
      }

      // Créer le compte
      await signup(email, password, firstName);
      navigate('/dashboard');
    } catch {
      alert('Failed to create an account');
    } finally {
      setLoading(false);
    }
  };

  const checkIfEmailExists = async (email: string): Promise<boolean> => {
    const auth = getAuth();
    
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      return signInMethods.length > 0;
    } catch (error) {
      console.error('Erreur lors de la vérification de l\'existence de l\'email:', error);
      return false; 
    }
  };

  return (
    <div className="limiter">
      <div className="container-login">
        <div className="wrap-login">
          <form className="login-form validate-form" onSubmit={handleSubmit}>
            <span className="login-form-title">
              Inscription
            </span>
            <span className="login-form-title">
              <VacancyIcon />
            </span>

            <div className="wrap-login_input validate-input" data-validate="Name">
              <input
                className="login_input"
                placeholder='Nom'
                type="text"
                name="nom"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <span className="focus-login_input" data-placeholder="Nom"></span>
            </div>

            <div className="wrap-login_input validate-input" data-validate="Valid email is: a@b.c">
              {emailError && <div className="error-message">{emailError}</div>}
              <input
                className={`login_input ${emailError && 'input-error'}`}
                placeholder='Email'
                type="text"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (emailError) setEmailError('');
                }}
              />
              <span className="focus-login_input" data-placeholder="Email"></span>
            </div>

            <div className="wrap-login_input validate-input" data-validate="Enter password">
              {passwordError && <div className="error-message">{passwordError}</div>}
              <span className="btn-show-pass" onClick={toggleShowPassword}>
                {showPassword ? <ClosePasswordEyeIcon /> : <PasswordEyeIcon />}
              </span>
              <input
                className="login_input"
                placeholder='Mot de passe'
                type={showPassword ? "text" : "password"}
                name="pass"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (passwordError) setPasswordError('');
                }}
              />
              <span className="focus-login_input" data-placeholder="Password"></span>
            </div>

            <div className="container-login-form-btn">
              <div className="wrap-login-form-btn">
                <div className="login-form-bgbtn"></div>
                <button className="login-form-btn" disabled={loading}>
                  {loading ? 'En cours...' : 'S\'ENREGISTRER'}
                </button>
              </div>
            </div>

            <div className="text-center">
              <span className="txt1">
                T'as dèja un compte ?
              </span><br></br>
              <a className="txt2" href="/login">
                Connecte toi
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
