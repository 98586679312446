// src/components/utils/GridEvent.tsx
import React from 'react';
import '../../styles/grid_event.scss';

interface GridEventProps {
  title: string;
  subtitle: string;
  progress?: number;
  participants?: string[];
  daysLeft?: number;
  color?: string;
}

const GridEvent: React.FC<GridEventProps> = ({ title, subtitle, progress, participants, daysLeft, color }) => {
  return (
    <div className="project-boxes">
      <div className="project-box-wrapper">
        <div className="project-box" style={{ backgroundColor: color }}>
          <div className="project-box-header">
            <div className="more-wrapper">
              <button className="project-btn-more">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-more-vertical"
                >
                  <circle cx="12" cy="12" r="1" />
                  <circle cx="12" cy="5" r="1" />
                  <circle cx="12" cy="19" r="1" />
                </svg>
              </button>
            </div>
          </div>
          <div className="project-box-content-header">
            <p className="box-content-header">{title}</p>
            <p className="box-content-subheader">{subtitle}</p>
          </div>
          {progress !== undefined && (
            <div className="box-progress-wrapper">
              <p className="box-progress-header">Progression</p>
              <div className="box-progress-bar">
                <span className="box-progress" style={{ width: `${progress}%` }}></span>
              </div>
              <p className="box-progress-percentage">{progress}%</p>
            </div>
          )}
          <div className="project-box-footer">
            {participants && (
              <div className="participants">
                {participants.map((participant, index) => (
                  <img key={index} src={participant} alt={`participant ${index}`} />
                ))}
                <button className="add-participant">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-plus"
                  >
                    <path d="M12 5v14M5 12h14" />
                  </svg>
                </button>
              </div>
            )}
            {daysLeft !== undefined && <div className="days-left">{daysLeft} jours restant</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridEvent;
