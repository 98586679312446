import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { db } from '../../firebaseConfig';
import { collection, query, where, getDocs, updateDoc, arrayUnion, doc } from 'firebase/firestore';

const JoinEvent: React.FC = () => {
  const [eventCode, setEventCode] = useState('');
  const navigate = useNavigate();
  const { currentUser, addEventToUser } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!currentUser) {
      console.error("User is not authenticated");
      return;
    }

    try {
      const q = query(collection(db, 'events'), where('eventCode', '==', eventCode));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        alert('Invalid event code');
        return;
      }

      const eventDoc = querySnapshot.docs[0];
      const eventId = eventDoc.id;

      // Ajouter l'utilisateur aux membres de l'événement
      await updateDoc(doc(db, 'events', eventId), {
        members: arrayUnion(currentUser.displayName)  
      });

      // Ajouter l'événement à la liste des événements de l'utilisateur
      await addEventToUser(eventId);

      navigate(`/event/${eventId}`);
    } catch (e) {
      console.error("Error joining event: ", e);
    }
  };

  return (
    <div>
      <h1>Rejoindre un événement</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Code de l'événement"
          value={eventCode}
          onChange={(e) => setEventCode(e.target.value)}
          required
        />
        <button type="submit">Rejoindre</button>
      </form>
      <Link to="/dashboard">Retour au dashboard</Link>
    </div>
  );
};

export default JoinEvent;
