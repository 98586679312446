import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ClosePasswordEyeIcon } from '../vendor/svg-icons/close-eye-password.svg';
import { ReactComponent as PasswordEyeIcon } from '../vendor/svg-icons/eye-password.svg';
import { ReactComponent as VacancyIcon } from '../vendor/svg-icons/coconut-tree-svgrepo-com.svg';
import '../styles/login.scss'

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/dashboard');
    } catch {
      alert('Failed to log in');
    }
  };
  
  return (
    <div className="limiter">
    <div className="container-login">
        <div className="wrap-login">
            <form className="login-form validate-form" onSubmit={handleSubmit}>
                <span className="login-form-title">
                Se connecter    
                </span>
                <span className="login-form-title">
                    <VacancyIcon />
                </span>

                <div className="wrap-login_input validate-input" data-validate="Valid email is: a@b.c">
                    <input 
                        className="login_input" 
                        placeholder='Email'
                        type="text" 
                        name="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="focus-login_input" data-placeholder="Email"></span>
                </div>

                <div className="wrap-login_input validate-input" data-validate="Enter password">
                    <span className="btn-show-pass-login" onClick={toggleShowPassword}>
                    {showPassword ? <ClosePasswordEyeIcon /> : <PasswordEyeIcon />}
                    </span>
                    <input 
                        className="login_input" 
                        placeholder='Mot de passe'
                        type={showPassword ? "text" : "password"}
                        name="pass" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="focus-login_input" data-placeholder="Password"></span>
                </div>

                <div className="container-login-form-btn">
                    <div className="wrap-login-form-btn">
                        <div className="login-form-bgbtn"></div>
                        <button className="login-form-btn">
                        SE CONNECTER
                        </button>
                    </div>
                </div>

                <div className="text-center">
                    <span className="txt1">
                        Pas encore de compte ?
                    </span><br></br>
                    <a className="txt2" href="/signup">
                        Inscris toi
                    </a>
                </div>
            </form>
        </div>
    </div>
</div>
  );
};

export default Login;