// src/components/CreateEvent.tsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { db } from '../../firebaseConfig';
import { collection, addDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

const CreateEvent: React.FC = () => {
  const [eventName, setEventName] = useState('');
  const navigate = useNavigate();
  const { currentUser, addEventToUser } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser) {
      console.error("User is not authenticated");
      return;
    }

    // Generate a simple ID for the event
    const generatedEventCode = uuidv4().substring(0, 6);
    
    try {
      const docRef = await addDoc(collection(db, 'events'), {
        name: eventName,
        ownerId: currentUser.uid,
        ownerName: currentUser.displayName, 
        members: [currentUser.displayName], 
        eventCode: generatedEventCode
      });

      console.log("Document written with ID: ", docRef.id);

      // Update the user's document to include the new event
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        events: arrayUnion(docRef.id)
      });

      addEventToUser(docRef.id);

      navigate(`/event/${docRef.id}`);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div>
      <h1>Créer un nouvel événement</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Nom de l'événement"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          required
        />
        <button type="submit">Créer</button>
      </form>
      <Link to="/dashboard">Retour au dashboard</Link>
    </div>
  );
};

export default CreateEvent;
